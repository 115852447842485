<template>
   <div class="muro">
      <div class="muro-sidebar">
         <SideBar />
      </div>
      <div class="muro-container">
         <div class="muro-tabs">
            <SectionTabs :tabs="tab_component" />
         </div>
         <div class="muro-body">
            <router-view />
         </div>
      </div>
   </div>
</template>

<script>
import SideBar from "@/components/SideBar.vue";
import SectionTabs from "@/components/Section/SectionTabs.vue";

export default {
   components: { SideBar, SectionTabs },
   data() {
      return {
         tab_component: [
            {
               name: "Posts",
               route: "muro-list",
            },
            {
               name: "Agregar",
               route: "muro-add",
            },
            {
               name: "Estadísticas",
               route: "muro-statistics",
            },
         ],
      };
   },
   mounted() {
      this.checkMyLogin(this.$route);
      setInterval(() => {
         this.checkIfUserIsLogged();
      }, 100);
   },
};
</script>